import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { Container } from 'starterUiComponents'
import PdfFirebaseUrlParser from '../../utils/pdf-firebase-url-parser'




const BULLETINS_QUERY = graphql`
  query {
    allMysqlBulletin(
      sort: { fields: [displaytextlink] }
    ) {
      nodes {
        calledby
        displaytextlink
        displaytextheader
        id
        returntotext2
        dataUrl
      }
    }
  }
`

const getOTB = (arr) => arr.filter((item) => item.displaytextlink ? item.displaytextlink.match(/^OTB-/i) : null)
const getVideo = (arr) => arr.filter((item) => item.displaytextlink ? item.displaytextlink.match(/^Video/i) : null)
const getNumeric = (arr) => arr.filter((item) => !item.displaytextlink || (!item.displaytextlink.match(/^OTB-/i) && !item.displaytextlink.match(/^Video/i)))

const NumericReferencesPage = () => {
  const data = useStaticQuery(BULLETINS_QUERY).allMysqlBulletin.nodes

  const allData = React.useMemo(() => {
    const otb = getOTB(data)
    const video = getVideo(data)
    const numeric = getNumeric(data)
    return [...numeric, ...video, ...otb]
  }, [data])

  /**
   * Gets all the NON quick references pdfs ordered by `calledby` text
   * @returns array
   */
  const getNumericReferences = () => {
    return allData.filter((item) => {
      return (
          !item.displaytextheader.includes('VIDEO') &&
        !item.displaytextheader.includes('QUICK REFERENCE')
      )
    })
  }

  const numericReferences = getNumericReferences()

  return (
    <Container>
      <h1>Tech Library Numeric Index</h1>
      <div>
        <ul>
          {numericReferences &&
            numericReferences.map((reference) => {
              const rel = PdfFirebaseUrlParser.getUrl(reference.dataUrl)
              const pdfLink = `/files/tech-library/${rel}`
              return (
                <li key={reference.id}>
                  <Link to={pdfLink}>{reference.displaytextlink}</Link>
                </li>
              )
            })}
        </ul>
      </div>
    </Container>
  )
}

export default NumericReferencesPage
