// const bucket = process.env.GATSBY_FIREBASE_STORAGE_BUCKET

const parser = {
  getUrl: (dataUrl) => {
    return dataUrl.replace(
      // `${bucket}/o/pdfs%2Ftech-library%2F`,
      /^.*tech-library(\%2F)?(\/)?/g,
      ''
    )
  },
}
export default parser

